// import Vue from 'vue'
import { Component, Watch } from "vue-property-decorator";
import baseUserContextPage from "./baseUserContextPage";
import axios, { AxiosPromise } from "axios";
import { AddedSkuToCartResponse, AddSkuToCart, AddSkuToCartRequest, productModel, productType } from "@/models/productModel";
import { mediaType, mediaModel } from "@/models/mediaModel";
import { sisasListiniModel } from "@/models/sisasPimListiniModel";
import { iProductOption } from "@/models/optionModel";
import { categoryModel } from "@/models/categoryModel";
import domainService from "@/services/domainService";
import { facetModel } from "@/models/facetModel";
import eventHub from "@/eventHub";
import { productRelation } from "@/models/productRelation";
var Qs = require("qs");

class product {
  code: string;
  quantity: number;
}

@Component({})
export default class multiInsertPage extends baseUserContextPage {
  isAddingToCart: boolean = false;
  products: product[] = [{ code: "", quantity: 1 }];
  allowKeyCodes = [37, 38, 39, 40, 8];
  allowKeys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

  onKeyDown($event) {
    if (this.allowKeyCodes.indexOf($event.keyCode) == -1 && this.allowKeys.indexOf($event.key) == -1) {
      $event.preventDefault();
    }
  }
  onBlur(index: number) {
    let product = this.getProductByIndex(index);
    if (product.quantity < 1) {
      product.quantity = 1;
    }
  }
  increase(index: number) {
    let product = this.getProductByIndex(index);
    if (product.quantity == null) product.quantity = 1;
    else product.quantity = Number.parseInt(product.quantity as any) + 1;
  }
  decrease(index: number) {
    let product = this.getProductByIndex(index);
    if (product.quantity == null || product.quantity <= 1) product.quantity = 1;
    else product.quantity = Number.parseInt(product.quantity as any) - 1;
  }
  getProductByIndex(index: number) {
    return this.products[index];
  }
  isCodeDuplicated(product: product): boolean {
    if (product == null || product.code == null || product.code.trimStart().trimEnd() == '')
      return false;
    var ps = this.products.filter(x => x.code == product.code);
    return ps.length > 1;
  }
  // addRow() {
  //   this.products.push({ code: "", quantity: 1 });
  // }
  @Watch("products", { deep: true })
  onItemChange(newVal: any) {
    // this.setQuantity();

    if (this.products.length == 0)
      this.products.push({ code: "", quantity: 1 });
    else {
      var c = this.products[this.products.length - 1].code;
      if (c != null && c.trim() != '')
        this.products.push({ code: "", quantity: 1 });
    }
  }
  onRemove(index: number) {
    this.products.splice(index, 1);
  }
  onSubmit(e) {
    e.preventDefault();
    // to do
    // console.log(JSON.stringify(this.products.filter(p => !!p.code)));

    var instance = this;
    var request = new AddSkuToCartRequest();
    this.products.filter(p => !!p.code).forEach(x => {

      if (request.products.find(rp => rp.sku == x.code) == null) {

        var p = new AddSkuToCart();
        p.customerId = instance.getCurrentCustomerId;
        p.sku = x.code;
        p.quantity = x.quantity;
        request.products.push(p);

      }
    });

    if (request.products.length > 0) {
      instance.isAddingToCart = true;
      axios
        .post<AddedSkuToCartResponse>(`cart/addproducts`, request)
        .then(res => {

          instance.setCurrentCart(res.data.cart);
          res.data.products.forEach(x => instance.$set(x, "source", "multi-insert-page"));
          eventHub.$emit(eventHub.events.CART_UPDATED, res.data.products);
          instance.isAddingToCart = false;

        })
        .catch(error => {
          console.error(error);
          instance.isAddingToCart = false;
        })
        .finally(() => (instance.isAddingToCart = false));
    }
    else {
      (instance as any).$notify({
        group: "msg",
        type: "warn",
        title: "Invio non riuscito",
        text: "Nessun codice articolo inserito"
      });
    }
  }
}
